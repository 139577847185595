import React, { Suspense, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Router from "./routes/Router";

import "./App.css";

import Loader from "./components/Loader/Loader";

import { IAPIStatusCode } from "./utility/apis/APIEndpointConfig";
import { getAccessTokenApi } from "./utility/apis/onboarding/OnBoardingApis";
import { storage } from "./utility/Storage";
import useIsMobile from "./utility/hooks/useIsMobile";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isMobileRef = useRef(isMobile);

  const getAccessTokenDetail = async () => {
    const getAccessTokenRes = await getAccessTokenApi();
    if (
      getAccessTokenRes.statusCode === IAPIStatusCode.SUCCESS &&
      "result" in getAccessTokenRes
    ) {
      storage.setSessionToken("lang", getAccessTokenRes?.result?.language);
      storage.setSessionToken("user_id", getAccessTokenRes?.result?.user_id);
      storage.setSessionToken("roleType", getAccessTokenRes?.result?.role);
    }
  };

  const interceptCalls401 = () => {
    return axios.interceptors.response.use(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
        }
        return response;
      },
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === IAPIStatusCode.UNAUTHENTICATED) {
            if (isMobileRef.current) {
              navigate("/");
            } else {
              navigate("/home");
            }
            setLoading(false);
          }
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  useEffect(() => {
    const interceptorId = interceptCalls401();
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, []);

  useEffect(() => {
    getAccessTokenDetail();
  }, []);

  if (loading) {
    return null;
  }
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      <Router />
    </Suspense>
  );
};

export default App;
