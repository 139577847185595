import { lazy } from "react";

const GetStarted = lazy(
  () => import("../../views/onboarding/GetStarted/GetStarted")
);
const LanguageSelection = lazy(
  () => import("../../views/onboarding/LanguageSelection/LanguageSelection")
);
const MobileOTPVerify = lazy(
  () => import("../../views/onboarding/MobileOTPVerify/MobileOTPVerify")
);

const OnboardingRoutes = [
  {
    path: "/",
    component: <LanguageSelection />,
  },
  {
    path: "/get-started",
    component: <GetStarted />,
  },
  { path: "/mobile-otp-verify", component: <MobileOTPVerify /> },
];

export default OnboardingRoutes;
