import React from "react";
import { Grid } from "@mui/material";

import Button from "../../Button/Button";
import GetImages from "../../GetImages/GetImages";

import { IButtonVariant } from "../../../models/button";

import style from "./SaveCancelTemplate.module.css";

interface IProps {
  isShowIcon?: boolean;
  saveLabel?: string;
  saveHandler: () => void;
  cancelHandler: () => void;
}
const SaveCancelTemplate: React.FC<IProps> = (props) => {
  const {
    isShowIcon = false,
    saveLabel = "Save",
    cancelHandler,
    saveHandler,
  } = props;
  return (
    <Grid item className={style.saveTemplateActionContainer}>
      <Grid item>
        <Button variant={IButtonVariant.WHITE} btnTrigger={cancelHandler}>
          Cancel
        </Button>
      </Grid>
      <Button btnTrigger={saveHandler}>
        {isShowIcon && (
          <GetImages name="PrescriptionSmallIcon" width="20" height="20" />
        )}
        {saveLabel}
      </Button>
    </Grid>
  );
};

export default SaveCancelTemplate;
