import React from "react";
import { Button as ButtonMUI, CircularProgress } from "@mui/material";

import style from "./Button.module.css";

import { IButtonType, IButtonVariant } from "../../models/button";

import { transformClasses } from "../../utility";

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
  btnTrigger?: (event: any) => void;
  type?: IButtonType;
  variant?: IButtonVariant;
  disabled?: boolean;
}
const Button: React.FC<IProps> = (props) => {
  const {
    children,
    btnTrigger,
    variant = IButtonVariant.ORANGE,
    isLoading = false,
    type = IButtonType.BUTTON,
    disabled = false,
  } = props;

  return (
    <ButtonMUI
      type={type}
      onClick={btnTrigger}
      sx={{
        "&.Mui-disabled": {
          backgroundColor: "lightgray!important",
          border: "1px solid lightgray!important",
          color: "darkgray!important",
        },
      }}
      className={transformClasses(
        style.btn,
        variant === IButtonVariant.TEXT
          ? style.text
          : variant === IButtonVariant.ORANGE
          ? style.orangeDefault
          : variant === IButtonVariant.ORANGE_ROUNDED
          ? transformClasses(style.orangeDefault, style.orangeRounded)
          : variant === IButtonVariant.WHITE
          ? style.whiteBtn
          : variant === IButtonVariant.GREY
          ? style.greyedBtn
          : style.orangeDefault
      )}
      disabled={disabled}
    >
      {isLoading ? (
        <CircularProgress
          size={20}
          sx={{
            color: variant === IButtonVariant.ORANGE ? "white" : "#f15a29",
          }}
        />
      ) : (
        children
      )}
    </ButtonMUI>
  );
};

export default Button;
