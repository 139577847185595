import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import OnboardingRoutes from "./onboarding";
import PatientRoutes from "./patient";
import DoctorRoutes from "./doctor";

import TopNavigation from "../components/Navigation/MobileNavigation/TopNavigation/TopNavigation";
import BottomNavigation from "../components/Navigation/MobileNavigation/BottomNavigation/BottomNavigation";
import WebTopNavigation from "../components/Navigation/WebNavigation/TopNavigation";
import DesktopBottomNavigation from "../components/BottomNavigation/BottomNavigation";
import PatientTopNavigation from "../components/Navigation/WebNavigation/PatientTopNavigation/PatientTopNavigation";
import CopyWriteFooter from "../components/CopyWriteFooter/CopyWriteFooter";

import { storage } from "../utility/Storage";
import useIsMobile from "../utility/hooks/useIsMobile";
import { IRoleType } from "../models/role";

const onboardingRoutes = ["/", "/get-started", "/mobile-otp-verify"];
const hideTopNavRoutes = ["/profile"];
const patientRoutes = [
  "/home",
  "/find-doctor",
  "/medicines",
  "/test-reports",
  "/health-tips",
];
const doctorRoutes = [
  "/availability-settings",
  "/availability-create",
  "/schedule",
  "/ip-dash",
  "/chats",
  "/profile",
];
const doctorBottomNavRoutes = ["/schedule", "/ip-dash", "/chats", "/profile"];

const Router: React.FC = () => {
  const location = useLocation();
  const { isMobile } = useIsMobile();

  const currentPath = location.pathname;
  const roleType = storage.getSessionToken("roleType");

  const isOnboarding = onboardingRoutes.includes(currentPath);
  const isPatientRoute = patientRoutes.includes(currentPath);
  const isDoctorRoute = doctorRoutes.includes(currentPath);
  const isHideTopNav = hideTopNavRoutes.includes(currentPath);
  const showBottomNavForDoctor = doctorBottomNavRoutes.includes(currentPath);

  return (
    <>
      {!isOnboarding && !isHideTopNav && (
        <>
          {isMobile && isPatientRoute && (
            <>
              <TopNavigation />
              <BottomNavigation />
            </>
          )}
          {!isMobile && isPatientRoute && <PatientTopNavigation />}
          {isDoctorRoute && isMobile && <TopNavigation />}
        </>
      )}
      {roleType === IRoleType.DOCTOR && !isMobile && <WebTopNavigation />}
      {showBottomNavForDoctor && isMobile && <BottomNavigation />}
      <Routes>
        {[...OnboardingRoutes, ...PatientRoutes, ...DoctorRoutes].map(
          (route, index) => (
            <Route path={route.path} element={route.component} key={index} />
          )
        )}
      </Routes>
      {!isMobile && isPatientRoute && <DesktopBottomNavigation />}

      {!isMobile && isDoctorRoute && <CopyWriteFooter />}
    </>
  );
};

export default Router;
