import GetImages from "../../components/GetImages/GetImages";

export const getStartedTutorial = [
  {
    image: <GetImages name="StayConnectedImage" width="304" height="245" />,
    title: "Stay Connected with our Neuro Team",
    description:
      "Connect with us today to ensure you're always in the loop with the best neuro care available.",
  },
  {
    image: <GetImages name="TestOnlineImage" width="268" height="279" />,
    title: "Book your neuro medicine and test online",
    description:
      "Stay on top of your wellness journey with our seamless online booking system.",
  },
  {
    image: <GetImages name="BookDoctorImage" width="310" height="199" />,
    title: "Book Best Neurosurgeons Doctors online",
    description:
      "Book your appointment today and take the first step towards better neurological health.",
  },
];

export const locationAccessDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "Doctors",
    subHeding: "Nearby",
  },
  {
    icon: <GetImages name="ClinicIcon" width="20" height="20" />,
    title: "Clinics",
    subHeding: "Nearby",
  },
  {
    icon: <GetImages name="LocationAccessIcon" width="36" height="36" />,
    title: "Directions",
    subHeding: "To them",
  },
];
