import React, { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import style from "./ChipMultiSelect.module.css";

import { transformClasses } from "../../utility";

import { useAppSelector } from "../../store/hooks";

interface ICheckBoxValues {
  value: string;
  available: boolean;
}
interface IProps {
  value?: string[];
  list: ICheckBoxValues[];
  selectedValuesList: (value: string[]) => void;
}
const ChipMultiSelect: React.FC<IProps> = (props) => {
  const { list, selectedValuesList, value } = props;

  const { doctorProfile } = useAppSelector((state) => state.topNavigation);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleCheckboxChange = (value: string, isAvailable: boolean) => {
    if (!isAvailable) return;
    if (selectedValues && selectedValues?.length > 0) {
      setSelectedValues((prevSelected: any) =>
        prevSelected.includes(value)
          ? prevSelected.filter((item: any) => item !== value)
          : [...prevSelected, value]
      );
    } else {
      setSelectedValues([value]);
    }
  };

  useEffect(() => {
    if (selectedValues) {
      selectedValuesList(selectedValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (
      doctorProfile.isAvailabilityOpen &&
      value?.length !== selectedValues.length
    ) {
      setSelectedValues(value!);
    }
  }, [value]);
  return (
    <Grid item className={style.slotSelectionContainer}>
      {list.map((info, index) => (
        <Grid
          item
          className={transformClasses(
            style.checkboxBtn,
            !info.available ? style.unavailableSlot : "",
            selectedValues && selectedValues?.includes(info.value)
              ? style.checkboxSelectedItem
              : style.checkboxItemDefault
          )}
          key={`${info.value}-${index}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                value={info.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  !info.available
                    ? null
                    : handleCheckboxChange(event?.target?.value, info.available)
                }
                className={style.checkBox}
              />
            }
            label={info.value}
            key={`${info.value}-${index}`}
            sx={{
              margin: "0px",
              "& .MuiFormControlLabel-label": {
                fontSize: "12px!important",
                color:
                  selectedValues && selectedValues?.includes(info.value)
                    ? "#FFF"
                    : !info.available
                    ? "#A8A8A8"
                    : "#000",
                ...(!info.available
                  ? {
                      textDecoration: "line-through",
                    }
                  : {}),
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChipMultiSelect;
