import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./CopyWriteFooter.module.css";

import { storage } from "../../utility/Storage";

import { IRoleType } from "../../models/role";

const CopyWriteFooter: React.FC = () => {
  const roleType = storage.getSessionToken("roleType");
  return (
    <Grid
      item
      className={style.container}
      sx={{
        backgroundColor: roleType === IRoleType.PATIENT ? "#e0e0e0" : "white",
      }}
    >
      <Typography component={"p"}>
        © 2024 The Brain Spine app. All Rights Reserved
      </Typography>
    </Grid>
  );
};

export default CopyWriteFooter;
