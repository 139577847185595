import { lazy } from "react";

const ScheduleListing = lazy(
  () => import("../../views/doctor/Schedule/Listing/Listing")
);
const AvailabilityListing = lazy(
  () => import("../../views/doctor/AvailabilitySettings/Listing/Listing")
);
const CreateEditAvailability = lazy(
  () =>
    import(
      "../../views/doctor/AvailabilitySettings/CreateEditAvailability/CreateEditAvailability"
    )
);
const DoctorProfile = lazy(() => import("../../views/doctor/Profile/Profile"));
const DoctorProfileDetail = lazy(
  () => import("../../views/common/DoctorProfile/DoctorProfile")
);
const MedicalRecords = lazy(
  () => import("../../views/common/MedicalRecords/MedicalRecords")
);
const CreatePrescription = lazy(
  () =>
    import(
      "../../views/doctor/Prescription/CreatePrescription/CreatePrescription"
    )
);
const CreatePrescriptionByCamera = lazy(
  () =>
    import(
      "../../views/doctor/Prescription/CreatePrescription/CreatePrescriptionByCamera"
    )
);
const Camera = lazy(() => import("../../views/common/Camera/Camera"));
const SearchAppointments = lazy(
  () =>
    import("../../views/doctor/Schedule/SearchAppointments/SearchAppointments")
);
const IPDashboard = lazy(
  () => import("../../views/doctor/IPDashboard/IPDashboard")
);
const SearchIPDashboard = lazy(
  () =>
    import("../../views/doctor/IPDashboard/SearchIPDashboard/SearchIPDashboard")
);

const Chat = () => {
  return <h1>Chat</h1>;
};

const DoctorRoutes = [
  { path: "/schedule", component: <ScheduleListing /> },
  { path: "/search-appointments", component: <SearchAppointments /> },
  { path: "/ip-dash", component: <IPDashboard /> },
  { path: "/search-ip", component: <SearchIPDashboard /> },
  { path: "/chats", component: <Chat /> },
  { path: "/profile", component: <DoctorProfile /> },
  { path: "/profile-detail", component: <DoctorProfileDetail /> },
  { path: "/availability-settings", component: <AvailabilityListing /> },
  { path: "/availability-create", component: <CreateEditAvailability /> },
  { path: "/patient-medical-records", component: <MedicalRecords /> },
  { path: "/create-prescription", component: <CreatePrescription /> },
  {
    path: "/create-prescription-camera",
    component: <CreatePrescriptionByCamera />,
  },
  {
    path: "/camera",
    component: <Camera />,
  },
];

export default DoctorRoutes;
