import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./NotFoundRecord.module.css";

import GetImages from "../GetImages/GetImages";

interface IProps {
  heading: string;
  icon?: boolean;
  tapHandler: () => void;
}
const NotFoundRecord: React.FC<IProps> = (props) => {
  const { heading, tapHandler, icon = true } = props;
  return (
    <Grid item className={style.container} onClick={tapHandler}>
      {icon && (
        <Grid item className={style.settingIconContainer}>
          <GetImages name="SettingOrangeIcon" width="36" height="36" />
        </Grid>
      )}
      <Typography component={"h6"} className={style.mainTitle}>
        {heading}
      </Typography>
      <Typography component={"p"} className={style.footerText}>
        Tap anywhere on the screen
      </Typography>
    </Grid>
  );
};

export default NotFoundRecord;
