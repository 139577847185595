import React from "react";
import { Grid, Typography } from "@mui/material";

import MobileImage from "../../assets/mobile.png";
import AppStoreIcon from "../../assets/appStore.png";
import PlayStoreIcon from "../../assets/playstore.png";

import style from "./DownloadAppBanner.module.css";

import { InputField } from "../InputField/InputFied";
import Button from "../Button/Button";

const DownloadAppBanner: React.FC = () => {
  return (
    <Grid item className={style.container}>
      <Grid item className={style.mobileImageContainer}>
        <img src={MobileImage} alt="mobile image" />
      </Grid>
      <Grid item className={style.textContainer}>
        <Grid item className={style.downloadAppNowTitle}>
          <Typography component={"h4"}>Download the App Now</Typography>
        </Grid>
        <Grid item className={style.appDescription}>
          <Typography component={"p"}>
            At Brain&Spine, we deliver excellence to every neuron and every
            life. Our focus is on accessible, innovative neurosurgical care,
            prioritizing personalized treatment. We're dedicated to transforming
            lives and positively impacting communities while maintaining the
            highest ethical standards.
          </Typography>
        </Grid>
        <Grid item className={style.getAppDownloadLink}>
          <InputField label="Mobile number*" placeholder="Mobile number*" />
          <Button>Get Download Link</Button>
        </Grid>
        <Grid item className={style.downloadBtnContainer}>
          <Grid item className={style.playStoreIconContainer}>
            <Button>
              <Grid item>
                <img
                  src={PlayStoreIcon}
                  alt="store icon"
                  width={42}
                  height={42}
                />
              </Grid>
              <Grid item className={style.downloadBtnText}>
                <Typography
                  component={"p"}
                  className={style.downloadTextHeader}
                >
                  GET IT ON
                </Typography>
                <Typography
                  component={"p"}
                  className={style.downloadTextFooter}
                >
                  Google Play
                </Typography>
              </Grid>
            </Button>
          </Grid>
          <Button>
            <Grid item>
              <img src={AppStoreIcon} alt="store icon" width={42} height={42} />
            </Grid>
            <Grid item className={style.downloadBtnText}>
              <Typography component={"p"} className={style.downloadTextHeader}>
                Download on the
              </Typography>
              <Typography component={"p"} className={style.downloadTextFooter}>
                App Store
              </Typography>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DownloadAppBanner;
