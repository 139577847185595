import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./BottomNavigation.module.css";

import CopyWriteFooter from "../CopyWriteFooter/CopyWriteFooter";
import GetImages from "../GetImages/GetImages";
import Button from "../Button/Button";
import DownloadAppBanner from "../DownloadAppBanner/DownloadAppBanner";

import { IButtonVariant } from "../../models/button";

const secondColumnLinks = [
  {
    title: "Company",
    items: [
      "Patient side",
      "Healthy individual",
      "About us",
      "Our App",
      "Careers",
      "Contact",
    ],
  },
  {
    title: "Terms Utility",
    items: [
      "Cookies Policy",
      "Terms & Conditions",
      "Privacy Policy",
      "Licenses",
    ],
  },
];

const thirdColumnLinks = [
  {
    title: "Patient",
    items: ["Treatment", "Success stories "],
  },
  {
    title: "Health Hub",
    items: ["Exercise videos", "Health tips"],
  },
  {
    title: "FAQ",
    items: [],
  },
];

const fourthColumnLinks = [
  {
    title: "Featured",
    items: [
      "Appointment booking",
      "Second opinion",
      "Find a doctor",
      "Free MRI & CT review",
      "Medicine delivery",
      "International patient",
      "Mental & Brain health check",
      "Spine health check",
    ],
  },
];

const socialIconsList = [
  "InstagramIcon",
  "FbIcon",
  "YouTubeIcon",
  "LinkedInIcon",
];

const BottomNavigation: React.FC = () => {
  return (
    <Grid item>
      <DownloadAppBanner />
      <Grid item className={style.bottomNavigationContainer}>
        <Grid item className={style.gbscAboutContainer}>
          <Grid item>
            <GetImages name="GBSCLargeLogo" width="120" height="70" />
          </Grid>
          <Grid item className={style.gbscAboutText}>
            <Typography component={"p"}>
              At Brain&Spine, we deliver excellence to every neuron and every
              life. Our focus is on accessible, innovative neurosurgical care,
              prioritizing personalized treatment. We're dedicated to
              transforming lives and positively impacting communities while
              maintaining the highest ethical standards.
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          {secondColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                {navItem.title}
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  {item}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {thirdColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                {navItem.title}
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  {item}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {fourthColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                {navItem.title}
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  {item}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Grid item className={style.downloadAppBtnContainer}>
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="MobileIcon" width="28" height="24" />
              Download the app{" "}
            </Button>
          </Grid>
          <Grid item className={style.customerSupportContainer}>
            <Typography component={"p"}>Customer Support</Typography>
            <Grid item className={style.telePhoneNumber}>
              <GetImages name="TelePhoneIcon" width="24" height="20" />
              <Typography component={"p"}>+91 - 9876543210</Typography>
            </Grid>
            <Grid item className={style.timingContactEmailContainer}>
              <Typography component={"p"}>10am - 8pm (Mon-Sat)</Typography>
              <Grid item className={style.emailContainer}>
                <GetImages name="MessageOrangeIcon" width="32" height="20" />
                <Typography component={"p"}>hello@brainandspine.com</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={style.socialAccountsContainer}>
            <Typography component={"p"}>Connect with us on</Typography>
            <Grid item className={style.socialLinks}>
              {socialIconsList.map((icon, index) => (
                <Grid item key={index} className={style.iconOuterContainer}>
                  <GetImages name={icon} width="20" height="20" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CopyWriteFooter />
    </Grid>
  );
};

export default BottomNavigation;
