import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./AvailabilitySettingWeb.module.css";

import Listing from "../Listing/Listing";
import Button from "../../../../components/Button/Button";
import GetImages from "../../../../components/GetImages/GetImages";
import CreateEditAvailability from "../CreateEditAvailability/CreateEditAvailability";

import { detail } from "../../../../store/slices/doctor/availabilitySettingsSlice";
import { useAppDispatch } from "../../../../store/hooks";

const AvailabilitySettingWeb: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <Grid item className={style.container}>
      <Grid item className={style.avilabilityTopContainer}>
        <Typography variant="h4">Availability Settings</Typography>
        <Grid item className={style.addNewAvailability}>
          <Button btnTrigger={() => dispatch(detail(null))}>
            <GetImages name="ScheduleIconSelected" width="30" height="24" />
            Add a new slot
          </Button>
        </Grid>
      </Grid>
      <Grid item className={style.availabilityListingCreateUpdateContainer}>
        <Grid item className={style.availabilityListing}>
          <Listing />
        </Grid>
        <Grid item className={style.availabilityCreateUpdateContainer}>
          <CreateEditAvailability />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailabilitySettingWeb;
