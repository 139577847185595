export const styles = {
    ml2: {
        marginLeft: "2px"
    },
    ml6: { marginLeft: "6px" },
    ml8: {
        marginLeft: "8px"
    },
    mr6: { marginRight: "6px" },
    mb0: {
        marginBottom: "0px!important"
    },
    mb20: {
        marginBottom: "20px!important"
    }
}