import React, { useCallback } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import style from "./ChipSelect.module.css";

import { IChipVariant } from "../../models/button";

import { transformClasses } from "../../utility";

import Carousel from "../Carousel/Carousel";
import GetImages from "../GetImages/GetImages";

interface IKeyValue {
  label: string | React.ReactNode;
  value: string;
  isBooked?: boolean;
  isSelection?: boolean;
}
interface IProps {
  data: IKeyValue[];
  variant?: IChipVariant;
  showCarousel?: boolean;
  showClearBtn?: boolean;
  selectedValue: string;
  setSelectedValue: (value: string, identifier?: string) => void;
  clear?: () => void;
  identifier?: string;
}
const ChipSelect: React.FC<IProps> = (props) => {
  const {
    data,
    variant = "",
    showCarousel = false,
    showClearBtn = false,
    selectedValue,
    setSelectedValue,
    clear,
    identifier,
  } = props;

  const handleClearBtn = () => {
    clear && clear();
    setSelectedValue("");
  };

  const clearBtn = () => {
    return (
      <>
        {selectedValue !== data[0].value && (
          <Grid item className={style.clearBtn} onClick={handleClearBtn}>
            <CloseIcon sx={{ width: 16, height: 16, color: "#ffffff" }} />
            <Typography component={"p"} className={style.clearText}>
              Clear
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const handleSelection = (event: React.ChangeEvent<any>) => {
    setSelectedValue(event.target.value, identifier!);
  };

  const chipItems =
    data?.length > 0
      ? data.map((info, index) => (
          <Grid
            item
            className={style.chipItemContainer}
            key={`${info.value}-${index}`}
          >
            <FormControlLabel
              key={`${info.label}-${index}`}
              value={info.value}
              className={
                info.value === selectedValue
                  ? transformClasses(
                      variant === IChipVariant.ROUNDED ? style.roundChip : "",
                      style.selected,
                      info.isBooked ? transformClasses(style.notAvailable) : ""
                    )
                  : transformClasses(
                      variant === IChipVariant.ROUNDED ? style.roundChip : "",
                      style.selectionDefault,
                      info.isBooked ? transformClasses(style.notAvailable) : ""
                    )
              }
              control={
                <Radio className={style.radioBtn} disabled={info.isBooked} />
              }
              onClick={handleSelection}
              label={
                <>
                  {info.label}{" "}
                  {info.isSelection && (
                    <>
                      {" "}
                      {selectedValue === info.value ? (
                        <GetImages
                          name="DownArrowWhiteIcon"
                          width="10"
                          height="6"
                        />
                      ) : selectedValue !== info.value ? (
                        <GetImages name="DownArrowIcon" width="10" height="6" />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "12px",
                },
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            />
          </Grid>
        ))
      : [];

  return (
    <RadioGroup row className={style.chipContainer}>
      {showCarousel ? (
        <Carousel
          items={[...chipItems, showClearBtn ? clearBtn() : []]}
          itemPerPage={4}
          isDotsShow={false}
          autoWidth
        />
      ) : showClearBtn ? (
        [...chipItems, clearBtn()]
      ) : (
        chipItems
      )}
    </RadioGroup>
  );
};

export default ChipSelect;
