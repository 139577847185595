import React from "react";
import { Grid, Typography } from "@mui/material";

import ChipSelect from "../ChipSelect/ChipSelect";

import style from "./SearchSuggestions.module.css";

interface IKeyValue {
  label: string | React.ReactNode;
  value: string;
}

interface IProps {
  clearSearchSuggestions: () => void;
  seachingText: string;
  foundDataList: IKeyValue[];
  selectSearchItem: string;
  typeOfSearch: string;
  setSelectSearchItem: (value: string) => void;
}
const SearchSuggestions: React.FC<IProps> = (props) => {
  const {
    seachingText,
    foundDataList,
    selectSearchItem,
    setSelectSearchItem,
    clearSearchSuggestions,
    typeOfSearch
  } = props;

  return (
    <Grid item className={style.searchContainer}>
      <Grid item className={style.foundRecordCountWithClearBtn}>
        <Typography component={"p"} className={style.recordCount}>
          {foundDataList?.length} {typeOfSearch} found for ‘{seachingText}’
        </Typography>
        <Typography
          component={"p"}
          className={style.clearSearch}
          onClick={clearSearchSuggestions}
        >
          Clear
        </Typography>
      </Grid>
      <Grid item className={style.searchedRecordSelectionContainer}>
        <ChipSelect
          data={foundDataList}
          selectedValue={selectSearchItem}
          setSelectedValue={setSelectSearchItem}
        />
      </Grid>
    </Grid>
  );
};

export default SearchSuggestions;
