import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import style from "./styles/Verification.module.css";

import Modal from "../Modal/Modal";
import { InputField } from "../InputField/InputFied";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";

import { IButtonType } from "../../models/button";
import { IMobileVerify } from "../../models/onboarding";

import { countryCode } from "../../utility/text";
import useIsMobile from "../../utility/hooks/useIsMobile";

interface IProps {
  heading: string;
  mobileSubmitHandler: (values: IMobileVerify) => void;
  btnLabel?: string;
  showCountryCode?: boolean;
  showHeaderNavigation?: boolean;
  headerNavigationText?: string;
  isOpen?: boolean;
  closeHandler?: () => void;
  mobileNo?: string;
}

const EnterMobile: React.FC<IProps> = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IMobileVerify>();

  const {
    heading,
    mobileSubmitHandler,
    btnLabel = "Get OTP",
    showCountryCode = true,
    showHeaderNavigation = false,
    headerNavigationText,
    isOpen,
    closeHandler,
    mobileNo,
  } = props;

  const { isMobile } = useIsMobile();
  const [isMobileDialogShow, setIsMobileDialogShow] = useState<boolean>(true);

  const mobileInputField = () => {
    return (
      <>
        {showHeaderNavigation && (
          <Grid
            item
            className={style.headerBackNav}
            onClick={() => closeHandler && closeHandler()}
          >
            <ArrowBackIosNewIcon fontSize={"inherit"} />{" "}
            <Typography component={"h3"} className={style.heading}>
              {headerNavigationText}
            </Typography>
          </Grid>
        )}
        <Grid item className={style.headerContainer}>
          <Typography component={"h3"} className={style.heading}>
            {heading}
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(mobileSubmitHandler)}>
          <Grid item>
            <Grid item className={style.mobileBox}>
              <Grid item className={style.mobileInputContainer}>
                {showCountryCode && (
                  <InputField
                    className={style.countryCodeInput}
                    label="Code"
                    variant="outlined"
                    placeholder="Code"
                    value={countryCode}
                    disabled
                  />
                )}
                <InputField
                  type="number"
                  {...register("mobileNo", {
                    required: "Mobile Number is required",
                    minLength: {
                      value: 10,
                      message: "Mobile Number should have 10 digits",
                    },
                  })}
                  label="Mobile Number"
                  variant="outlined"
                  placeholder="Mobile Number"
                  fullWidth
                />
              </Grid>
              <Grid item className={style.mobileVerifyErrorContainer}>
                {errors.mobileNo && (
                  <Typography component={"span"} className={style.errorMessage}>
                    {errors.mobileNo.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item className={style.buttonContainer}>
              <Button type={IButtonType.SUBMIT}>{btnLabel}</Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  useEffect(() => {
    if (mobileNo) {
      setValue("mobileNo", mobileNo);
    }
  }, [mobileNo]);
  return (
    <>
      {isMobile ? (
        <Modal
          isOpen={isOpen}
          closeHandler={() => closeHandler && closeHandler()}
          lineBottomBackground="white"
        >
          {mobileInputField()}
        </Modal>
      ) : (
        <Dialog
          isOpen={isMobileDialogShow}
          closeHandler={() => setIsMobileDialogShow(!isMobileDialogShow)}
        >
          {mobileInputField()}
        </Dialog>
      )}
    </>
  );
};

export default EnterMobile;
