import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import style from "./TopNavigation.module.css";

import GetImages from "../../../GetImages/GetImages";
import Confirmation from "../../../Confirmation/Confirmation";

import { IRoleType } from "../../../../models/role";
import {
  IDoctorConsultationSelection,
  IDoctorConsultationType,
  IDoctorProfileDropdownEnums,
} from "../../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  detail,
  fetchAvailabilitySettings,
} from "../../../../store/slices/doctor/availabilitySettingsSlice";
import { resetScheduleSelection } from "../../../../store/slices/doctor/scheduleSlice";
import {
  fetchFindDoctor,
  resetDoctorSearch,
  setDoctorSearch,
} from "../../../../store/slices/patient/findDoctorSlice";
import { setDoctorProfileDropdown } from "../../../../store/slices/doctor/topNavigationSlice";

import { deleteAvailabilitySettingApi } from "../../../../utility/apis/doctor/availabilitySetting";
import { IAPIStatusCode } from "../../../../utility/apis/APIEndpointConfig";
import { storage } from "../../../../utility/Storage";
import useDebounce from "../../../../utility/hooks/useDebouncer";
import { doctorProfileDropdownDispatchingEnums } from "../../../../utility";
import useCancelScheduleAppointments from "../../../../utility/hooks/useCancelScheduleAppointments";

interface INavItem {
  path: string;
  icon: React.ReactNode;
  title: string;
  subText: string;
  subTextHandler?: () => void;
  locationIcon: boolean;
  secondIcon: React.ReactNode;
  isShowSearch: boolean;
}
const TopNavigation: React.FC = () => {
  const navigate = useNavigate();

  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const dispatch = useAppDispatch();

  const [confirmDeleteAvailability, setConfirmDeleteAvailability] =
    useState<boolean>(false);
  const roleType = storage.getSessionToken("roleType");
  const [navItem, setNavItem] = useState<INavItem>();
  const [showCancelSchedule, setShowCancelSchedule] = useState<boolean>(false);
  const [searchDoctor, setSearchDoctor] = useState<string>("");

  const { cancelScheduleAppointments } = useCancelScheduleAppointments();

  const debouncedDoctorSearch = useDebounce(searchDoctor, 500);

  const subText = useLocation();

  const mapLocation: any =
    storage.getSessionToken("subText") &&
    JSON.parse(storage.getSessionToken("subText") as any);
  const locationAttriutes = `&latitude=${mapLocation?.latitude}&longitude=${mapLocation?.longitude}`;

  const scheduleSelection = useAppSelector((state) => state.schedule.selection);
  const isDoctorSearch = useAppSelector(
    (state) => state.findDoctor.isDoctorSearch
  );

  const navItems = {
    [IRoleType.PATIENT]: [
      {
        path: "/home",
        icon: <GetImages name="HomeNavIcon" width="36" height="36" />,
        title: "Hi Kundan!",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: <GetImages name="MessageNavIcon" width="24" height="24" />,
        isShowSearch: true,
      },
      {
        path: "/find-doctor",
        icon: <GetImages name="DoctorNavIcon" width="36" height="36" />,
        title: "Find Doctors",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: (
          <Grid item onClick={() => dispatch(setDoctorSearch())}>
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/medicines",
        icon: <GetImages name="MedicineNavIcon" width="36" height="36" />,
        title: "Get Medicines",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: null,
        isShowSearch: true,
      },
      {
        path: "/test-reports",
        icon: <GetImages name="TestNavIcon" width="36" height="36" />,
        title: "Book Tests",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: <GetImages name="CartNavIcon" width="24" height="24" />,
        isShowSearch: true,
      },
      {
        path: "/health-tips",
        icon: <GetImages name="TvNavIcon" width="36" height="36" />,
        title: "Health Hub",
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ],
    [IRoleType.DOCTOR]: [
      {
        path: "/availability-settings",
        icon: <GetImages name="SettingOrangeIcon" width="36" height="36" />,
        title: "Availability Settings",
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item onClick={() => navigate(-1)}>
            <CloseIcon sx={{ height: 24, weight: 24 }} />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/availability-create",
        icon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
        title: "Availability Settings",
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={style.availabilityBtnsContainer}>
            {availabilityDetail && (
              <Grid
                item
                className={style.trashBtn}
                onClick={() =>
                  setConfirmDeleteAvailability(!confirmDeleteAvailability)
                }
              >
                <GetImages name="TrashIcon" width="24" height="24" />
              </Grid>
            )}
            <Grid
              item
              onClick={() => {
                navigate(-1);
                dispatch(detail(null));
              }}
            >
              <CloseIcon sx={{ height: 24, weight: 24 }} />
            </Grid>
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/schedule",
        icon: <GetImages name="ScheduleNavIcon" width="36" height="36" />,
        title: "Schedule",
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={style.scheduleTopIconContainer}>
            <Link to={"/search-appointments"}>
              <GetImages name="NavSearchIcon" width="24" height="24" />
            </Link>
            <Link to={"/availability-settings"}>
              <GetImages name="SettingGreyedIcon" width="24" height="24" />
            </Link>
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/ip-dash",
        icon: <GetImages name="IpDashNavIcon" width="36" height="36" />,
        title: "IQ City Hospital",
        locationIcon: false,
        subTextHandler: () =>
          dispatch(
            setDoctorProfileDropdown(
              doctorProfileDropdownDispatchingEnums[
                IDoctorProfileDropdownEnums.CHANGE_HOSPITAL
              ]
            )
          ),
        subText: "Change",
        secondIcon: (
          <Link to={"/search-ip"}>
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Link>
        ),
        isShowSearch: false,
      },
      {
        path: "/chats",
        icon: <GetImages name="ChatNavIcon" width="36" height="36" />,
        title: "Chats",
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ],
  };

  const setNavItemsData = () => {
    if (roleType === IRoleType.PATIENT || roleType == IRoleType.DOCTOR) {
      navItems[roleType].forEach((item, index) => {
        if (item.path.includes(subText.pathname)) {
          setNavItem(item);
        }
      });
    }
  };

  const deleteAvailabilitySetting = async () => {
    const deleteAvailabilityResp = await deleteAvailabilitySettingApi(
      availabilityDetail?.id!
    );
    if (deleteAvailabilityResp.statusCode === IAPIStatusCode.SUCCESS) {
      navigate(-1);
      dispatch(fetchAvailabilitySettings());
      dispatch(detail(null));
      setConfirmDeleteAvailability(!confirmDeleteAvailability);
    }
  };

  const openCloseCancelAppointmentConfirmation = () =>
    setShowCancelSchedule(!showCancelSchedule);

  useEffect(() => {
    if (debouncedDoctorSearch.length === 0) {
      dispatch(resetDoctorSearch());
      return;
    }
    if (debouncedDoctorSearch) {
      dispatch(fetchFindDoctor(`${locationAttriutes}&search=${searchDoctor}`));
    }
  }, [debouncedDoctorSearch]);

  useEffect(() => {
    setNavItemsData();
  }, [subText]);
  return (
    <Grid item className={style.topNavigationContainer}>
      <Grid item className={style.topNavigationTitleContainer}>
        {scheduleSelection.isSelectionOn ? (
          <Grid item className={style.selectedScheduleContainer}>
            <CloseIcon
              sx={{ height: 24, weight: 24 }}
              onClick={() => dispatch(resetScheduleSelection())}
            />
            <Grid item className={style.selectedScheduleAppointmentCount}>
              <Typography component={"h4"}>Select items</Typography>
              <Typography component={"p"}>
                {scheduleSelection.selectedSchedules.length} Slots,{" "}
                {scheduleSelection.selectedAppointments.length} Appointments
              </Typography>
            </Grid>
          </Grid>
        ) : isDoctorSearch ? (
          <Grid item>
            <input
              type="text"
              value={searchDoctor}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchDoctor(event.target.value)
              }
              placeholder="Type here"
              autoFocus
            />
          </Grid>
        ) : (
          <>
            <Grid item className={style.topNavigationIcon}>
              {navItem?.icon}
            </Grid>
            <Grid item className={style.topNavigationTextContainer}>
              <Typography component={"p"} className={style.topNavigationTitle}>
                {navItem?.title}
              </Typography>
              {navItem?.subText && (
                <Grid item className={style.topNavigationLocation}>
                  {navItem?.locationIcon && (
                    <GetImages
                      name="DoctorNavLocationIcon"
                      width="12"
                      height="12"
                    />
                  )}
                  <Typography
                    component={"p"}
                    className={style.topNavigationLocationText}
                    onClick={() =>
                      navItem?.subTextHandler && navItem?.subTextHandler()
                    }
                  >
                    {navItem?.subText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item className={style.topNavigationIconContainer}>
        {scheduleSelection.isSelectionOn ||
          (navItem?.isShowSearch && (
            <GetImages name="NavSearchIcon" width="24" height="24" />
          ))}
        {scheduleSelection.isSelectionOn ||
          (navItem?.secondIcon && (
            <Grid item className={style.topNavigationSecondIconContainer}>
              {navItem?.secondIcon}
            </Grid>
          ))}
        {scheduleSelection.isSelectionOn && (
          <Grid
            item
            className={style.topNavigationSecondIconContainer}
            onClick={() => setShowCancelSchedule(!showCancelSchedule)}
          >
            <GetImages name="CancelIcon" width="28" height="24" />
            <Typography component={"p"} className={style.cancelText}>
              Cancel
            </Typography>
          </Grid>
        )}
      </Grid>
      {confirmDeleteAvailability && (
        <Confirmation
          isOpen={confirmDeleteAvailability}
          closeHandler={() =>
            setConfirmDeleteAvailability(!confirmDeleteAvailability)
          }
          title="Delete Availability"
          alterMessage={"Do you Really Want to delete"}
          name={
            availabilityDetail?.type_of_consultation ===
            IDoctorConsultationType.IN_CLINIC
              ? availabilityDetail?.clinic_name ?? ""
              : IDoctorConsultationSelection.VIDEO_CONSULTATION
          }
          actionHandler={deleteAvailabilitySetting}
        />
      )}
      {showCancelSchedule && (
        <Confirmation
          isOpen={showCancelSchedule}
          closeHandler={openCloseCancelAppointmentConfirmation}
          title="Cancel Appointments"
          alterMessage={"Do you Really Want to Cancel Appointment"}
          actionHandler={() => {
            cancelScheduleAppointments();
            openCloseCancelAppointmentConfirmation();
          }}
        />
      )}
    </Grid>
  );
};

export default TopNavigation;
