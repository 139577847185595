import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import GetImages from "../../../GetImages/GetImages";
import Button from "../../../Button/Button";
import MobileOTPVerify from "../../../../views/onboarding/MobileOTPVerify/MobileOTPVerify";

import style from "./PatientTopNavigation.module.css";

import PatientImage from "../../../../assets/patientProfile.png";

import { storage } from "../../../../utility/Storage";

const PatientTopNavigation: React.FC = () => {
  const [isMobileOTPVerifyShow, setIsMobileOTPVerifyShow] =
    useState<boolean>(false);

  const token = storage.getSessionToken("token");

  const navigate = useNavigate();

  const patientNavItems = [
    {
      path: "/home",
      label: "Home",
    },
    {
      path: "/find-doctor",
      label: "Doctors",
    },
    {
      path: "/medicines",
      label: "Medicines",
    },
    {
      path: "/test-reports",
      label: "Tests",
    },
    {
      path: "/health-tips",
      label: "Health",
    },
  ];
  return (
    <Grid item className={style.container}>
      <Grid item>
        <GetImages name="GBSCLogo" width="84" height="51" />
      </Grid>
      <Grid item className={style.searchInputLocationContainer}>
        <Grid item className={style.locationNameInputContainer}>
          <GetImages name="LocationAccessSmallIcon" width="24" height="20" />
          <Typography component={"p"}>Durgapur</Typography>
        </Grid>
        <input placeholder="Search doctors, clinics, tests here" />
        <Grid item className={style.searchIcon}>
          <GetImages name="NavSearchIcon" width="24" height="24" />
        </Grid>
      </Grid>
      <Grid item className={style.navItemsContainer}>
        {patientNavItems.map((nav, index) => (
          <Grid
            item
            key={index}
            className={style.navItemLabel}
            onClick={() => navigate(nav.path)}
          >
            <Typography component={"p"}>{nav.label}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item className={style.languageSelectionContainer}>
        <GetImages name="WorldIcon" width="24" height="20" />
        <Typography component={"p"}>English</Typography>
      </Grid>
      <Grid item className={style.telephoneEmergencyContainer}>
        <Grid item className={style.telephoneContainer}>
          <GetImages name="TelePhoneIcon" width="24" height="20" />
          <Typography component={"p"}>9721 108 108</Typography>
        </Grid>
        <Grid item className={style.helplineTime}>
          <Typography component={"p"}>24/7 helpline</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {token ? (
          <img src={PatientImage} alt="profile" />
        ) : (
          <Button
            btnTrigger={() => setIsMobileOTPVerifyShow(!isMobileOTPVerifyShow)}
          >
            Login
          </Button>
        )}
      </Grid>
      {isMobileOTPVerifyShow && <MobileOTPVerify />}
    </Grid>
  );
};

export default PatientTopNavigation;
