import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { InputAdornment, Typography } from "@mui/material";

import { InputField } from "../InputField/InputFied";

import style from "./TimePicker.module.css";

import { ITimePickerType } from "../../models/doctor";

interface IProps {
  type: ITimePickerType;
  label: string;
  value: any;
  handleSaveTime: (value: string, type: ITimePickerType) => void;
}

const TimePicker: React.FC<IProps> = (props) => {
  const { label, handleSaveTime, type, value } = props;

  const [selectedTime, setSelectedTime] = useState<null | Dayjs>(null);

  const saveSelectedValue = (values: any) => {
    const value = dayjs(values?.$d).format("HH:mm");
    handleSaveTime(value, type);
  };

  useEffect(() => {
    const selectedTimeValue = value
      ? dayjs(`${dayjs().format("YYYY-MM-DD")}T${value}`)
      : null;
    setSelectedTime(selectedTimeValue);
  }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["MobileTimePicker", "MobileTimePicker"]}
        sx={{ overflow: "hidden", border: "8px" }}
      >
        <MobileTimePicker
          value={selectedTime}
          onChange={(event: any) => setSelectedTime(event)}
          onAccept={saveSelectedValue}
          label={label}
          openTo="hours"
          slots={{
            textField: (params) => (
              <InputField
                {...params}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography component={"p"} className={style.selectText}>
                        Select
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            ),
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
export default TimePicker;
