import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IMedicalRecordDetail } from "../../../models/ApiRequestResponse/doctor";
import { IMedicalRecordTypeEnum } from "../../../models/doctor";

import { medicalRecordListApi } from "../../../utility/apis/doctor/prescription";

interface IFetchMedicalRecord {
  memberId: string;
  type?: IMedicalRecordTypeEnum;
  bookingId?: string;
}

interface IMedicalReport {
  image: string;
  isCapturedSave: boolean;
  selectedTab?: number;
  date: any;
  healthCategoryId: string;
}

export const fetchMedicalRecordList = createAsyncThunk(
  "medical-record",
  async ({ memberId, type, bookingId }: IFetchMedicalRecord) => {
    const response = await medicalRecordListApi(memberId, type, bookingId);
    return response;
  }
);

const medicalRecordsSlice = createSlice({
  name: "medicalRecords",
  initialState: {
    isLoading: true as boolean,
    medicalRecords: {} as IMedicalRecordDetail,
    medicalReport: {
      image: "",
      isCapturedSave: false,
      selectedTab: 0,
    } as IMedicalReport,
  },
  reducers: {
    setMedicalReport: (state: any, action) => {
      state.medicalReport[action.payload.identifier] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedicalRecordList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMedicalRecordList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.medicalRecords = action.payload.result;
        }
      })
      .addCase(fetchMedicalRecordList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setMedicalReport } = medicalRecordsSlice.actions;
export default medicalRecordsSlice.reducer;
