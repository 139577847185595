import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import style from "./styles/Verification.module.css";

import Modal from "../Modal/Modal";
import { InputField } from "../InputField/InputFied";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";

import { transformClasses } from "../../utility";
import useIsMobile from "../../utility/hooks/useIsMobile";

import { IOtpVerify } from "../../models/onboarding";
import { IButtonType } from "../../models/button";

interface IProps {
  handleOTPSubmit: (value: IOtpVerify) => void;
  resendOTP: () => void;
  navigateToMobileVerify: () => void;
  mobileNo: string;
}
const VerifyOTP: React.FC<IProps> = (props) => {
  const [seconds, setSeconds] = useState<number>(60);
  const [isTimerStart, setIsTimerStart] = useState<boolean>(false);
  const [isOTPDialogShow, setIsOTPDialogShow] = useState<boolean>(true);

  const { isMobile } = useIsMobile();

  const { handleOTPSubmit, resendOTP, navigateToMobileVerify, mobileNo } =
    props;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IOtpVerify>();

  const handleResendOTP = () => {
    if (isTimerStart) return;
    setIsTimerStart(true);
    resendOTP();
  };

  const verifyOTPScreen = useCallback(() => {
    return (
      <Grid item>
        <Grid item className={style.otpHeader}>
          <Grid item>
            <Typography component={"h3"} className={style.heading}>
              Enter OTP to Continue
            </Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={style.subHeading}>
              We have sent a 6 digit code on your mobile Number {mobileNo}
            </Typography>
            <Typography
              component={"span"}
              className={transformClasses(style.orangeText, style.otpLabeling)}
              onClick={navigateToMobileVerify}
            >
              change number
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleOTPSubmit)}>
          <Grid item>
            <Grid item className={style.otpInnerContainer}>
              <Grid item className={style.otpInputContainer}>
                <InputField
                  type="number"
                  {...register("otp", {
                    required: "OTP is required",
                    minLength: {
                      value: 6,
                      message: "OTP should have 6 digits",
                    },
                  })}
                  label="OTP"
                  variant="outlined"
                  placeholder="OTP"
                  fullWidth
                />
              </Grid>
              <Typography
                component={"p"}
                className={transformClasses(
                  isTimerStart ? style.disabledResend : style.orangeText,
                  style.otpLabeling,
                  style.resendContainer
                )}
                onClick={handleResendOTP}
              >
                {isTimerStart && (
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.subHeading,
                      style.otpLabeling
                    )}
                  >
                    {seconds}{" "}
                  </Typography>
                )}
                Resend OTP
              </Typography>
              <Grid item className={style.mobileVerifyErrorContainer}>
                {errors.otp && (
                  <Typography component={"span"} className={style.errorMessage}>
                    {errors.otp.message}
                  </Typography>
                )}
              </Grid>

              <Grid item className={style.termsContainer}>
                <Typography
                  component={"p"}
                  className={transformClasses(
                    style.otpTermConditionText,
                    style.otpLabeling
                  )}
                >
                  By Entering OTP, I agree with the
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.orangeText,
                      style.otpLabeling
                    )}
                  >
                    {" "}
                    Terms and condition
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid item className={style.buttonContainer}>
              <Grid item>
                <Button type={IButtonType.SUBMIT}>Verify and Done</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  }, [isTimerStart, seconds]);

  useEffect(() => {
    if (isTimerStart) {
      if (seconds > 0) {
        window.setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setIsTimerStart(false);
        setSeconds(60);
      }
    }
  }, [isTimerStart, seconds]);

  useEffect(() => {
    setIsTimerStart(true);
  }, []);

  return (
    <>
      {isMobile ? (
        <Modal lineBottomBackground="white">{verifyOTPScreen()}</Modal>
      ) : (
        <Dialog
          isOpen={isOTPDialogShow}
          closeHandler={() => setIsOTPDialogShow(!isOTPDialogShow)}
        >
          {verifyOTPScreen()}
        </Dialog>
      )}
    </>
  );
};

export default VerifyOTP;
