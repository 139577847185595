import {
  ICancelScheduleAppointmentsRequest,
  ICancelScheduleAppointmentsResponse,
  IDoctorScheduleSlots,
  IRescheduleAppointmentRequest,
  IRescheduleAppointmentResponse,
  IScheduleListing,
  ISearchedAppointmentResponse,
  ISendOtpPatientCreationRequest,
  ISendOtpPatientCreationResponse,
} from "../../../models/ApiRequestResponse/doctor";
import { IScheduleAppointmentDateRangeParams } from "../../../models/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { deleteMethod, get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getScheduleAppointmentListingApi = async (
  params: IScheduleAppointmentDateRangeParams
): Promise<IScheduleListing | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.scheduleList;
    if (params.start && params.end) {
      const { start, end } = params;
      url += `?start=${start}&end=${end}`;
    }
    const response = await get<IScheduleListing>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorScheduleSlotApi = async (
  id?: string
): Promise<IDoctorScheduleSlots | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.scheduleSlots;
    const response = await get<IDoctorScheduleSlots>(id ? url + `/${id}` : url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const sendOtpPatientCreationApi = async (
  requestBody: ISendOtpPatientCreationRequest
): Promise<ISendOtpPatientCreationResponse | GBSCError> => {
  try {
    const response = await post<ISendOtpPatientCreationResponse>(
      APIEndpoint.doctor.sendOtpPatientCreation,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const cancelScheduleAppointmentsApi = async (
  requestBody: ICancelScheduleAppointmentsRequest
): Promise<ICancelScheduleAppointmentsResponse | GBSCError> => {
  try {
    const response = await deleteMethod<ICancelScheduleAppointmentsResponse>(
      APIEndpoint.doctor.cancelScheduleAppointment,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const rescheduleCancelledAppointmentsApi = async (
  requestBody: IRescheduleAppointmentRequest
): Promise<IRescheduleAppointmentResponse | GBSCError> => {
  try {
    const response = await post<IRescheduleAppointmentResponse>(
      APIEndpoint.doctor.reschedule,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const searchAppointmentsApi = async (
  search: string
): Promise<ISearchedAppointmentResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.doctor.searchAppointments}?search=${search}`;
    const response = await get<ISearchedAppointmentResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
