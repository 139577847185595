import {
  ICreatePrescriptionPayload,
  ICreatePrescriptionResponse,
  IDeletePrescriotionTemplateResponse,
  IMedicalRecordResponse,
  IMedicineTestResponse,
  IPrescriptionConfigResponse,
  IPrescriptionTemplateCreateUpdatePayload,
  IPrescriptionTemplateCreateUpdateResponse,
  IPrescriptionTemplateListResponse,
  ISaveMedicalRecordRequest,
  ISaveMedicalRecordResponse,
  ISearchPrescriptionAttributesResponse,
  IUploadFileToS3Response,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IMedicalRecordTypeEnum,
  IPrescriptionAttributeType,
  IPrescriptionFilterType,
} from "../../../models/doctor";

import { GBSCError } from "../../../models/GBSCError";

import { deleteMethod, get, patch, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const prescriptionConfigApi = async (
  filterType: IPrescriptionFilterType
): Promise<IPrescriptionConfigResponse | GBSCError> => {
  try {
    const response = await get<IPrescriptionConfigResponse>(
      `${APIEndpoint.doctor.prescriptionConfig}?filter=${filterType}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createPrescriptionApi = async (
  requestBody: ICreatePrescriptionPayload
): Promise<ICreatePrescriptionResponse | GBSCError> => {
  try {
    const response = await post<ICreatePrescriptionResponse>(
      APIEndpoint.doctor.createPrescription,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const searchMedicineTestApi = async (
  type: string,
  searchVal: string
): Promise<IMedicineTestResponse | GBSCError> => {
  try {
    const response = await get<IMedicineTestResponse>(
      `${APIEndpoint.doctor.searchMedicineTest}${type}?keyword=${searchVal}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const medicalRecordListApi = async (
  memberId: string,
  type?: string,
  bookingId?: string,
  healthCategoryId?: string
): Promise<IMedicalRecordResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.doctor.medicalRecordList}?memberId=${memberId}`;
    url += type
      ? `&type=${type}`
      : healthCategoryId
      ? `&healthCategoryId=${healthCategoryId}`
      : bookingId
      ? `&bookingId=${bookingId}`
      : "";
    const response = await get<IMedicalRecordResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const saveMedicalRecordApi = async (
  requestBody: ISaveMedicalRecordRequest
): Promise<ISaveMedicalRecordResponse | GBSCError> => {
  try {
    const response = await post<ISaveMedicalRecordResponse>(
      APIEndpoint.doctor.saveMedicalRecord,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const uploadFileToS3Api = async (
  type: IMedicalRecordTypeEnum,
  requestBody: any
): Promise<IUploadFileToS3Response | GBSCError> => {
  try {
    const response = await post<IUploadFileToS3Response>(
      `${APIEndpoint.doctor.uploadReportToS3}?action=${type}`,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const searchPrescriptionAttributesApi = async (
  type: IPrescriptionAttributeType,
  search: string
): Promise<ISearchPrescriptionAttributesResponse | GBSCError> => {
  try {
    const url = `${APIEndpoint.doctor.searchPrescriptionAttribute}?search=${search}&type=${type}`;
    const response = await get<ISearchPrescriptionAttributesResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createPrescriptionTemplateApi = async (
  requestBody: IPrescriptionTemplateCreateUpdatePayload
): Promise<IPrescriptionTemplateCreateUpdateResponse | GBSCError> => {
  try {
    const response = await post<IPrescriptionTemplateCreateUpdateResponse>(
      APIEndpoint.doctor.prescriptionTemplate,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updatePrescriptionTemplateApi = async (
  templateId: string,
  requestBody: IPrescriptionTemplateCreateUpdatePayload
): Promise<IPrescriptionTemplateCreateUpdateResponse | GBSCError> => {
  try {
    const response = await patch<IPrescriptionTemplateCreateUpdateResponse>(
      `${APIEndpoint.doctor.prescriptionTemplate}/${templateId}`,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const prescriptionTemplateListApi = async (): Promise<
  IPrescriptionTemplateListResponse | GBSCError
> => {
  try {
    const response = await get<IPrescriptionTemplateListResponse>(
      APIEndpoint.doctor.prescriptionTemplate
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const deletePrescriptionTemplateApi = async (
  templateId: string
): Promise<IDeletePrescriotionTemplateResponse | GBSCError> => {
  try {
    const response = await deleteMethod<IDeletePrescriotionTemplateResponse>(
      `${APIEndpoint.doctor.prescriptionTemplate}/${templateId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
