import React, { ChangeEvent, useState } from "react";
import { Grid, Typography } from "@mui/material";

import GetImages from "../../GetImages/GetImages";
import MealTime from "../MealDoseTimePopUp/MealTime";
import DoseFrequency from "../MealDoseTimePopUp/DoseFrequency";

import style from "./MedicineTestListRemoveManagerWeb.module.css";

import {
  IMealDoseTimeTypesEnums,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  addValuesToExistingMedicinesTest,
  removePrescriptionTemplateItem,
} from "../../../store/slices/doctor/prescriptionSlice";

interface IProps {
  type: ITitleInputValuesListEnums;
}
const MedicineTestListRemoveManagerWeb: React.FC<IProps> = (props) => {
  const { type } = props;
  const [showMealDoseTimePopup, setShowMealDoseTimePopup] = useState({
    popUpType: null as IMealDoseTimeTypesEnums | null,
    selectedPopUpIndex: 0,
  });

  const isMedicine = type === ITitleInputValuesListEnums.MEDICINE;
  const medicineOrTest = isMedicine
    ? ITitleInputValuesListEnums.MEDICINE
    : ITitleInputValuesListEnums.TEST;

  const dispatch = useAppDispatch();
  const { template } = useAppSelector((state) => state.prescription);
  const { create }: any = template;

  let debounceTimeout: NodeJS.Timeout | null = null;

  const showCloseMealDoseTimePopUpHandler = (
    popUpType: IMealDoseTimeTypesEnums | null,
    selectedPopUpIndex: number
  ) => {
    setShowMealDoseTimePopup({ popUpType, selectedPopUpIndex });
  };

  const removeAddedMedicineTestHandler = (
    identifier: ITitleInputValuesListEnums,
    itemIndex: number,
    value?: string
  ) => {
    const removeItemDetails = {
      identifier,
      value,
      itemIndex,
    };
    dispatch(removePrescriptionTemplateItem(removeItemDetails));
  };

  const addNoteHandler = (
    identifier: ITitleInputValuesListEnums,
    value: string,
    itemIndex: number
  ) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      dispatch(
        addValuesToExistingMedicinesTest({
          identifier,
          field: "note",
          value,
          itemIndex,
        })
      );
    }, 100);
  };
  return (
    <>
      {create &&
        create[medicineOrTest]?.length > 0 &&
        create[medicineOrTest]?.map(
          (medicineTestDetail: any, index: number) => (
            <Grid item className={style.container} key={index}>
              <Grid item className={style.medicineTestNameContainer}>
                <Typography component={"p"}>
                  {medicineTestDetail?.name}
                </Typography>
              </Grid>
              <Grid item className={style.medicineTestActionContainer}>
                {isMedicine && (
                  <Grid item className={style.medicineActionContainer}>
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.mealTime}
                        onClick={() =>
                          showCloseMealDoseTimePopUpHandler(
                            IMealDoseTimeTypesEnums.MEAL_TIME,
                            index
                          )
                        }
                      >
                        {medicineTestDetail?.whenText ?? "When To Take"}
                      </Typography>
                      {showMealDoseTimePopup.popUpType ===
                        IMealDoseTimeTypesEnums.MEAL_TIME &&
                        showMealDoseTimePopup.selectedPopUpIndex === index && (
                          <Grid
                            item
                            className={style.mealTimeSelectionContainer}
                          >
                            <MealTime
                              selectedIndex={index}
                              closeHandler={() =>
                                showCloseMealDoseTimePopUpHandler(null, index)
                              }
                            />
                          </Grid>
                        )}
                    </Grid>
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.doseDays}
                        onClick={() =>
                          showCloseMealDoseTimePopUpHandler(
                            IMealDoseTimeTypesEnums.DOSE_TIME,
                            index
                          )
                        }
                      >
                        {medicineTestDetail?.frequencyText ??
                          "Frequency & Medicine"}
                      </Typography>
                      {showMealDoseTimePopup.popUpType ===
                        IMealDoseTimeTypesEnums.DOSE_TIME &&
                        showMealDoseTimePopup.selectedPopUpIndex === index && (
                          <Grid
                            item
                            className={style.doseFreqSelectionContainer}
                          >
                            <DoseFrequency
                              selectedIndex={index}
                              closeHandler={() =>
                                showCloseMealDoseTimePopUpHandler(null, index)
                              }
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                )}
                <Grid item className={style.addNote}>
                  <input
                    value={medicineTestDetail?.note}
                    type="text"
                    placeholder="Add note"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      addNoteHandler(medicineOrTest, event.target.value, index)
                    }
                  />
                </Grid>
                <Grid
                  item
                  className={style.trashIcon}
                  onClick={() =>
                    removeAddedMedicineTestHandler(
                      isMedicine
                        ? ITitleInputValuesListEnums.MEDICINE
                        : ITitleInputValuesListEnums.TEST,
                      index,
                      medicineTestDetail?.name
                    )
                  }
                >
                  <GetImages name="TrashIcon" width="24" height="24" />
                </Grid>
              </Grid>
            </Grid>
          )
        )}
    </>
  );
};

export default MedicineTestListRemoveManagerWeb;
