import { IAPIStatusCode } from "../utility/apis/APIEndpointConfig";
import {
  IMedicine,
  INextVisitType,
  ITagOtherHealthProblemDiagnosis,
  ITest,
} from "./ApiRequestResponse/doctor";

export enum IDoctorAvailabilityPlatform {
  VIDEO_CALL = "VIDEO_CALL",
  IN_CLINIC = "IN_CLINIC",
  BOTH = "BOTH",
}

export enum IFindDoctorAvailabilityPlatform {
  VIDEO_CALL = "videoConsultation",
  IN_CLINIC = "inClinic",
  BOTH = "both",
}

export enum IMakeAppointmentEvent {
  VIDEO_CALL = 0,
  IN_CLINIC = 1,
}

export enum IDoctorScheduleConsultationType {
  IN_CLINIC = "inClinic",
  VIDEO_CALL = "videoConsultation",
}

export enum IDoctorProfileStatus {
  VERIFIED,
  ASK_FREE,
  NEW,
}
export enum IAppointmetEnums {
  CREATION,
  MOBILE_CHECK,
  PATIENT_DETAIL,
  SUCCESS,
  CHECKOUT,
  OTP,
}

export interface IAppointmentCreation {
  creation: boolean;
  mobileCheck?: boolean;
  patientDetail?: boolean;
  checkout?: boolean;
  otp?: boolean;
  success: boolean;
  patientAlreadyExist?: boolean;
}
export enum IDoctorConsultationSelection {
  IN_CLINIC = "In Clinic",
  VIDEO_CONSULTATION = "Video Consultation",
}

export enum IDoctorConsultationType {
  IN_CLINIC = "1",
  VIDEO_CONSULTATION = "2",
}
export enum IDropDownSelectionType {
  CONSULTATION = "CONSULTATION",
  CLINIC_NAME = "CLINIC_NAME",
  SLOT_DURATION = "SLOT_DURATION",
  BUFFER_TIME = "BUFFER_TIME",
  GENDER = "GENDER",
  CHANGE_NAME = "CHANGE_NAME",
  SPECIALITY = "speciality",
  TYPE_OF_CONSULTATION = "TypeOfConsultation",
  EXPERINCE = "yearOfExperience",
  DISTANCE = "distance",
  LANGUAGES = "language",
  HOSPITAL_CLINICS = "HOSPITAL_CLINICS",
  ICU = "ICU",
  WARD = "WARD",
}

export enum ITimePickerType {
  START_TIME = "START_TIME",
  END_TIME = "END_TIME",
}

export enum IPatientAppointmentStatus {
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}

export enum IDays {
  SUN = "Sun",
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
}

export interface IGender {
  isOpen: boolean;
  value: string;
}

export interface IMakeAppointmentFromDoctorSlice {
  type: IDoctorAvailabilityPlatform;
  dateSlotTime: string;
  slotId: string;
  clinicId?: string;
  clinicName: string;
  memberId: string;
  fee: number;
  name: string;
  age: string;
  gender: string;
}

export interface ICheckNumberErrors {
  message: string;
  statusCode: IAPIStatusCode | string;
}

export enum IDoctorProfileDropdownEnums {
  PROFILE = "PROFILE",
  REVIEW = "REVIEW",
  AVAILABILITY = "AVAILABILITY",
  CLINICAL_TEAM = "CLINICAL_TEAM",
  TEMPLATES = "TEMPLATES",
  APPOINTMENT = "APPOINTMENT",
  IN_PATIENT = "IN_PATIENT",
  CHANGE_HOSPITAL = "CHANGE_HOSPITAL",
}

export enum IPrescriptionFilterType {
  PRESCRIPTION = "prescription",
}

export enum IVitalsIdentifiers {
  BP = "bp",
  PULSE = "pulse",
  TEMP = "temperature",
  WEIGHT = "weight",
  HEIGHT = "height",
  BMI = "bmi",
}

export enum ITitleInputValuesListEnums {
  TAG = "tag",
  OTHER_HEALTH_PROBLEMS = "otherHealthProblem",
  COMPLAINT = "complaint",
  DIAGNOSIS = "diagnosis",
  MEDICINE = "medicine",
  TEST = "test",
  DIET_AND_EXERCISE = "dietAndExercise",
  NOTE = "note",
}

export enum IAccordionInputChipListEnums {
  NEXT_VISIT = "NEXT_VISIT",
  HEALTH_CATEGORIES = "HEALTH_CATEGORIES",
}
export interface ICreatePrescription {
  bp: string;
  pulse: number;
  temperature: number;
  weight: number;
  height: number;
  bmi: number;
  tag: ITagOtherHealthProblemDiagnosis[];
  otherHealthProblem: ITagOtherHealthProblemDiagnosis[];
  complaint: string[];
  diagnosis: ITagOtherHealthProblemDiagnosis[];
  dietAndExercise: string;
  note: string;
  test: ITest[];
  medicine: IMedicine[];
  healthCategoryId: string;
  nextVisitType: INextVisitType;
  nextVisitNo: number;
}

export enum ISaveTypePrescription {
  SAVE_PRESCRIPTION = "1",
  END_CONSULTATION = "2",
}

export interface IMedicineTestModalShow {
  medicine: boolean;
  test: boolean;
}

export enum IMedicalRecordTypeEnum {
  All = "ALL",
  PRESCRIPTION = "PRESCRIPTION",
  REPORT = "REPORT",
}

export enum ISharePrescriptionIcons {
  WHATSAPP_ICON = "WhatsAppIcon",
  GMAIL_ICON = "GmailIcon",
  PRINT_ICON = "PrintIcon",
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export enum IPrescriptionAttributeType {
  Tag = 1,
  HealthProblem = 2,
  Diagnosis = 3,
}

export interface IScheduleAppointmentDateRangeParams {
  start?: string;
  end?: string;
}

export enum ITemplateCreateEnum {
  NAME = "name",
  DIAGNOSIS = "diagnosis",
  MEDICINE = "medicine",
  TEST = "test",
  DIET_AND_EXERCISE = "dietAndExercise",
}
export interface IMedicineTestSuggestionData {
  testId: string;
  name: string;
  medicineId: string;
}

export enum IMealDoseTimeTypesEnums {
  MEAL_TIME = "MEAL_TIME",
  DOSE_TIME = "DOSE_TIME",
}

export enum IRoomType {
  ICU = "ICU",
  WARD = "WARD",
}

export enum IPDashboardFilterType {
  ACTIVE = "ACTIVE",
  DISCHARGE = "DISCHARGE",
  WARD = "WARD",
  ICU = "ICU",
}

export interface IpDashboardFilterModal {
  [IPDashboardFilterType.ICU]: boolean;
  [IPDashboardFilterType.WARD]: boolean;
}
export interface ISelectedDate {
  startDate: string;
  endDate: string;
  selectedDayText?: string;
}

export enum IPDashboardPatientAdmitStatus {
  ADMIT = "ADMIT",
  DISCHARGED = "DISCHARGED",
}

export interface IBeforeAdmitPatient {
  mobileCheck: boolean;
  otpVerify: boolean;
}
