import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./GridIconText.module.css";

interface IProps {
  icon: React.ReactNode;
  title: string;
  subHeding: string;
}

const GridIconText: React.FC<IProps> = (props) => {
  const { icon, title, subHeding } = props;
  return (
    <Grid item className={style.cardContainer}>
      <Grid item className={style.cardIcon}>
        {icon}
      </Grid>
      <Grid item className={style.titleContainer}>
        <Typography component={"p"} className={style.cardTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component={"p"} className={style.cardSubHeading}>
          {subHeding}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GridIconText;
