import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GBSCError } from "../../../models/GBSCError";
import {
  IDoctorHospitalDetail,
  IDoctorHospitalListResponse,
  IHospitalPatient,
  IHospitalRooms,
} from "../../../models/ApiRequestResponse/doctor";
import { IRoomType } from "../../../models/doctor";

import {
  getDoctorHospitalListApi,
  getDoctorHospitalPatientListApi,
  getHospitalRoomsListApi,
} from "../../../utility/apis/doctor/ipDashboard";

interface IDoctorHospitalPatients {
  hospitalId: string;
  discharged?: boolean;
  start_date?: string;
  end_date?: string;
  room_id?: string;
  room_bed_id?: string;
  keyword?: string;
}
export const fetchDoctorHospitalListing = createAsyncThunk<
  IDoctorHospitalListResponse | GBSCError
>("doctor/fetchDoctorHospitalListing", async () => {
  const response = await getDoctorHospitalListApi();
  return response;
});

export const fetchDoctorHospitalPatientListing = createAsyncThunk(
  "doctor/fetchDoctorHospitalPatientListing",
  async ({
    hospitalId,
    start_date,
    end_date,
    discharged,
    keyword,
    room_id,
    room_bed_id,
  }: IDoctorHospitalPatients) => {
    const response = await getDoctorHospitalPatientListApi(
      hospitalId,
      start_date,
      end_date,
      discharged,
      room_id,
      room_bed_id,
      keyword
    );
    return response;
  }
);

export const fetchHospitalRoomsListing = createAsyncThunk(
  "doctor/fetchHospitalRoomsListing",
  async ({ hospitalId }: { hospitalId: string }) => {
    const response = await getHospitalRoomsListApi(hospitalId);
    return response;
  }
);

const ipDashboardSlice = createSlice({
  name: "ipDashboard",
  initialState: {
    hospitals: [] as IDoctorHospitalDetail[],
    hospitalPatients: [] as IHospitalPatient[],
    rooms: {
      allRooms: [] as IHospitalRooms[],
      ward: [] as IHospitalRooms[],
      icu: [] as IHospitalRooms[],
    },
    selectedHospitalId: "",
    isLoading: true as boolean,
  },
  reducers: {
    setSelectedHospitalId: (state, action) => {
      state.selectedHospitalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // doctor hospital listing
      .addCase(fetchDoctorHospitalListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorHospitalListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.hospitals = action.payload.result;
          state.selectedHospitalId = action.payload.result[0]?.hospital_id;
        }
      })
      .addCase(fetchDoctorHospitalListing.rejected, (state) => {
        state.isLoading = false;
      })
      //  doctor hospital patient listing
      .addCase(fetchDoctorHospitalPatientListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorHospitalPatientListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.hospitalPatients = action.payload.result;
        }
      })
      .addCase(fetchDoctorHospitalPatientListing.rejected, (state) => {
        state.isLoading = false;
      })
      //  hospital room listing
      .addCase(fetchHospitalRoomsListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHospitalRoomsListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.rooms.allRooms = action.payload.result;
          state.rooms.icu = action.payload.result.filter(
            (room, index) => room.type === IRoomType.ICU
          );
          state.rooms.ward = action.payload.result.filter(
            (room, index) => room.type === IRoomType.WARD
          );
        }
      })
      .addCase(fetchHospitalRoomsListing.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setSelectedHospitalId } = ipDashboardSlice.actions;
export default ipDashboardSlice.reducer;
