import React, { useState } from "react";
import { Grid } from "@mui/material";

import SaveCancelTemplate from "../SaveCancelTemplate/SaveCancelTemplate";
import ChipMultiSelect from "../../ChipMultiSelect/ChipMultiSelect";
import ChipSelect from "../../ChipSelect/ChipSelect";

import style from "./styles/MealDoseTimePopUp.module.css";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addValuesToExistingMedicinesTest } from "../../../store/slices/doctor/prescriptionSlice";

import { IChipVariant } from "../../../models/button";
import { ITitleInputValuesListEnums } from "../../../models/doctor";

interface IProps {
  selectedIndex: number;
  closeHandler: () => void;
}
const DoseFrequency: React.FC<IProps> = (props) => {
  const { closeHandler, selectedIndex } = props;

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [daysFreq, setDaysFreq] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const { config } = useAppSelector((state) => state.prescription);

  const frequencyList = config?.frequency
    .split(",")
    .map((freq, index) => ({ value: freq, available: true }));

  const chipSelectData = config?.nextVisitType?.map((nextVisit: string) => ({
    label: nextVisit,
    value: nextVisit,
  }));

  const selectedMedicineFrequency = (selectedValues: string[]) => {
    const result: string[] = [];
    selectedValues.forEach((item, index) => {
      frequencyList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(val.value);
        }
      });
    });
    setDaysFreq(result);
  };

  const saveHandler = () => {
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequency",
        value: daysFreq.join(","),
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequencyDays",
        value: +duration,
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequencyText",
        value: `${daysFreq.join(",")} for ${duration} ${selectedValue}`,
        itemIndex: selectedIndex,
      })
    );
    closeHandler();
  };
  return (
    <Grid item className={style.container}>
      <Grid item className={style.doseFreqSelection}>
        <Grid item className={style.doseFreqeChipContainer}>
          <ChipMultiSelect
            value={[""]}
            list={frequencyList}
            selectedValuesList={selectedMedicineFrequency}
          />
        </Grid>

        <Grid item className={style.inputBtnContainer}>
          <input
            type="text"
            placeholder="Type here"
            onChange={(event) => setDuration(event.target.value)}
            autoFocus
          />
          <Grid item className={style.nextVisitTypeSelector}>
            <ChipSelect
              variant={IChipVariant.DEFAULT}
              data={chipSelectData}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={style.saveCancelActionContainer}>
        <SaveCancelTemplate
          saveHandler={saveHandler}
          cancelHandler={closeHandler}
        />
      </Grid>
    </Grid>
  );
};

export default DoseFrequency;
