import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { InputAdornment, Typography } from "@mui/material";

import { InputField } from "../InputField/InputFied";

import style from "./DateSelect.module.css";

interface IProps {
  label?: string;
  value: any;
  onChange: (value: any) => void;
}
const DateSelect: React.FC<IProps> = (props) => {
  const { label = "Date", value, onChange } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={value}
          onChange={onChange}
          label={label}
          slots={{
            textField: (params) => (
              <InputField
                {...params}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography component={"p"} className={style.selectText}>
                        Select
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            ),
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateSelect;
