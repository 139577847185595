import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AvailabilityListCard from "../../../../components/AvailabilityListCard/AvailabilityListCard";
import ChipSelect from "../../../../components/ChipSelect/ChipSelect";
import NotFoundRecord from "../../../../components/NotFoundRecord/NotFoundRecord";
import Loader from "../../../../components/Loader/Loader";
import CreateAction from "../../../../components/CreateAction/CreateAction";

import style from "./Listing.module.css";

import { IChipVariant } from "../../../../models/button";
import { IDays } from "../../../../models/doctor";
import { IScheduleAvailabilitySetting } from "../../../../models/ApiRequestResponse/doctor";

import {
  dayFilterListing,
  getAvailabilityListByDay,
} from "../../../../utility";
import useIsMobile from "../../../../utility/hooks/useIsMobile";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchAvailabilitySettings } from "../../../../store/slices/doctor/availabilitySettingsSlice";

const Listing: React.FC = () => {
  const [availabilityFromFilter, setAvailabilityFromFilter] = useState<{
    isFilter: boolean;
    data: IScheduleAvailabilitySetting[];
  }>({
    isFilter: false,
    data: [],
  });
  const [selectedFilterDay, setSelectedFilterDay] = useState<any>(
    dayFilterListing[0].value
  );

  const { isMobile } = useIsMobile();

  const dispatch = useAppDispatch();
  const availabilitySettingInfo = useAppSelector((state) => state.availability);

  const navigate = useNavigate();

  const moveToCreateAvailablity = () => navigate("/availability-create");

  const handleAvailabilityFilteration = (day: IDays) => {
    const filteredAvailability = getAvailabilityListByDay(
      availabilitySettingInfo.list,
      day
    );
    setAvailabilityFromFilter({ isFilter: true, data: filteredAvailability });
  };

  const AvailabilityListing = useCallback(
    (availabilityList: IScheduleAvailabilitySetting[]) => {
      return (
        <Grid item className={style.container}>
          <Grid item className={style.daysFilterContainer}>
            <ChipSelect
              data={dayFilterListing}
              selectedValue={selectedFilterDay}
              setSelectedValue={setSelectedFilterDay}
              variant={IChipVariant.ROUNDED}
              showCarousel={isMobile}
              showClearBtn={true}
            />
          </Grid>
          {availabilityList.length > 0 ? (
            <>
              {availabilityList.map((list, index) => (
                <AvailabilityListCard {...list} key={index} />
              ))}
              <CreateAction createHandler={moveToCreateAvailablity} />
            </>
          ) : (
            <NotFoundRecord
              heading="Add your availability to accept bookings"
              tapHandler={moveToCreateAvailablity}
            />
          )}
        </Grid>
      );
    },
    [availabilitySettingInfo.list, availabilityFromFilter]
  );

  useEffect(() => {
    if (availabilitySettingInfo.list.length === 0) {
      dispatch(fetchAvailabilitySettings());
    }
  }, []);

  useEffect(() => {
    if (selectedFilterDay !== "All") {
      handleAvailabilityFilteration(selectedFilterDay);
    } else {
      setAvailabilityFromFilter({ isFilter: false, data: [] });
    }
  }, [selectedFilterDay]);

  if (availabilitySettingInfo.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {availabilityFromFilter.isFilter
        ? AvailabilityListing(availabilityFromFilter.data)
        : AvailabilityListing(availabilitySettingInfo.list)}
    </>
  );
};

export default Listing;
