import React from "react";
import { Grid, Typography } from "@mui/material";

import { ITitleInputValuesListEnums } from "../../../models/doctor";

import style from "./MedicineTestListRemoveManager.module.css";

interface IProps {
  identifier: ITitleInputValuesListEnums;
  name: string;
  removeItemHandler: (
    identifier: ITitleInputValuesListEnums,
    name: string
  ) => void;
  mealTime?: string;
  mealDays?: string;
  note: string;
}
const MedicineTestListRemoveManager: React.FC<IProps> = (props) => {
  const { identifier, name, note, mealDays, mealTime, removeItemHandler } =
    props;
  return (
    <Grid item className={style.medicineTestListContainer}>
      <Grid item className={style.medicineTestNameContainer}>
        <Typography component={"p"} className={style.medicineTestName}>
          {name}
        </Typography>
        <Typography
          component={"p"}
          className={style.removeMedicineTest}
          onClick={() => removeItemHandler(identifier, name)}
        >
          remove
        </Typography>
      </Grid>
      {identifier === ITitleInputValuesListEnums.MEDICINE && (
        <>
          <Typography component={"p"} className={style.mealTime}>
            {" "}
            {mealTime}
          </Typography>
          <Typography component={"p"} className={style.mealDays}>
            {mealDays}
          </Typography>
        </>
      )}
      {note && (
        <Grid item className={style.noteContainer}>
          <Typography component={"p"} className={style.note}>
            {note}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MedicineTestListRemoveManager;
