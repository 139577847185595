import React from "react";
import { Typography } from "@mui/material";

import style from "./Title.module.css";

interface IProps {
  children: React.ReactNode;
}
const Title: React.FC<IProps> = (props) => {
  const { children } = props;
  return (
    <Typography component={"p"} className={style.title}>
      {children}
    </Typography>
  );
};

export default Title;
