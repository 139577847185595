import React, { useCallback, useEffect, useState } from "react";
import {
  BottomNavigationAction,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation as Navigation } from "@mui/material";

import GetImages from "../../GetImages/GetImages";
import Button from "../../Button/Button";
import DoctorProfileDetail from "../../../views/common/DoctorProfile/DoctorProfile";
import DoctorProfile from "../../../views/doctor/Profile/Profile";
import Dialog from "../../Dialog/Dialog";
import CreateEditTemplate from "../../../views/doctor/Prescription/CreateEditTemplate/CreateEditTemplate";
import AvailabilitySettingWeb from "../../../views/doctor/AvailabilitySettings/AvailabilitySettingWeb/AvailabilitySettingWeb";

import { IRoleType } from "../../../models/role";
import { IDoctorProfileDropdownEnums } from "../../../models/doctor";

import style from "./TopNavigation.module.css";

import { storage } from "../../../utility/Storage";
import { doctorProfileDropdownDispatchingEnums } from "../../../utility";

import NavImage from "../../../assets/navImage.png";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setDoctorProfileDropdown } from "../../../store/slices/doctor/topNavigationSlice";

enum IModalShowHide {
  SHOW = "SHOW",
  HIDE = "HIDE",
}
enum DropdownType {
  ADD_PATIENT = "ADD_PATIENT",
  DOCTOR_PROFILE = "DOCTOR_PROFILE",
}
const navigation = {
  [IRoleType.PATIENT]: [
    {
      path: "/home",
      icon: <GetImages name="HomeIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="HomeSelectedIcon" width="24" height="24" />
      ),
      label: "Home",
    },
    {
      path: "/find-doctor",
      icon: <GetImages name="DoctorIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="DoctorSelectedIcon" width="24" height="24" />
      ),
      label: "Doctors",
    },
    {
      path: "/medicines",
      icon: <GetImages name="MedicineIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="MedicineSelectedIcon" width="24" height="24" />
      ),
      label: "Medicines",
    },
    {
      path: "/test-reports",
      icon: <GetImages name="TestIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="TestSelectedIcon" width="24" height="24" />
      ),
      label: "Tests",
    },
    {
      path: "/health-tips",
      icon: <GetImages name="TvIcon" width="24" height="24" />,
      selectedIcon: <GetImages name="TvSelectedIcon" width="24" height="24" />,
      label: "Health",
    },
  ],
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedIcon" width="24" height="24" />
      ),
      label: "Schedule",
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="IpDashSelectedIcon" width="24" height="24" />
      ),
      label: "IP Dashboard",
    },
  ],
};

const addPatientInfo = [
  {
    path: "",
    iconName: "ScheduleSelectedSmallIcon",
    text: "Appointment",
    identifier: IDoctorProfileDropdownEnums.APPOINTMENT,
  },
  {
    path: "",
    iconName: "ClinicSmallIcon",
    text: "In Patient",
    identifier: IDoctorProfileDropdownEnums.IN_PATIENT,
  },
];

const doctorProfileItems = [
  {
    path: "",
    iconName: "SmileSmallIcon",
    text: "Profile",
    identifier: IDoctorProfileDropdownEnums.PROFILE,
  },
  {
    path: "",
    iconName: "SettingSmallIcon",
    text: "Availability",
    identifier: IDoctorProfileDropdownEnums.AVAILABILITY,
  },
  {
    path: "",
    iconName: "StarSmallIcon",
    text: "Reviews",
    identifier: IDoctorProfileDropdownEnums.REVIEW,
  },
  {
    path: "",
    iconName: "ClinicTeamSmallIcon",
    text: "Clinical Team",
    identifier: IDoctorProfileDropdownEnums.CLINICAL_TEAM,
  },
  {
    path: "",
    iconName: "TemplatesSmallIcon",
    text: "Templates",
    identifier: IDoctorProfileDropdownEnums.TEMPLATES,
  },
];

interface INavItemInfo {
  path: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: string;
}
const TopNavigation: React.FC = () => {
  const location = useLocation();

  const [navIndex, setNavIndex] = useState<number>(
    location.state?.navItem ?? 0
  );
  const [navItems, setNavItems] = useState<INavItemInfo[]>();
  const [showAddPatient, setShowAddPatient] = useState(null);
  const [showDoctorProfile, setShowDoctorProfile] = useState(null);
  const isShowAddPatient = Boolean(showAddPatient);
  const isShowDoctorProfile = Boolean(showDoctorProfile);

  const dispatch = useAppDispatch();
  const doctorProfileDropdown = useAppSelector(
    (state) => state.topNavigation.doctorProfile
  );

  const navigate = useNavigate();
  const roleType = storage.getSessionToken("roleType");

  const setNavigationItemsByRoleType = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      setNavItems(navigation[roleType]);
    }
  };

  const navigateToPage = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      navigate(navigation[roleType][navIndex].path);
    }
  };

  const handleShowHideDropDown = (
    type: IModalShowHide,
    dropDownType: DropdownType,
    event?: any
  ) => {
    if (type === IModalShowHide.HIDE) {
      dropDownType === DropdownType.ADD_PATIENT
        ? setShowAddPatient(null)
        : setShowDoctorProfile(null);
      return;
    }
    dropDownType === DropdownType.ADD_PATIENT
      ? setShowAddPatient(event.currentTarget)
      : setShowDoctorProfile(event.currentTarget);
  };

  const openCloseDoctorProfileDropdown = useCallback(
    (identifier: IDoctorProfileDropdownEnums) => {
      setShowDoctorProfile(null);
      setShowAddPatient(null);
      dispatch(
        setDoctorProfileDropdown(
          doctorProfileDropdownDispatchingEnums[identifier]
        )
      );
    },
    []
  );

  useEffect(() => {
    setNavigationItemsByRoleType();
  }, []);

  useEffect(() => {
    navigateToPage();
  }, [navIndex]);

  return (
    <Grid item className={style.navigationContainer}>
      <Navigation
        showLabels
        value={navIndex}
        sx={{ justifyContent: "flex-start", background: "transparent" }}
        onChange={(event, newValue) => setNavIndex(newValue)}
      >
        {navItems &&
          navItems.map((nav, index) => (
            <BottomNavigationAction
              label={nav.label}
              icon={navIndex === index ? nav.selectedIcon : nav.icon}
              key={index}
              sx={{
                color: "#A8A8A8",
                fontSize: "16px!important",
                lineHeight: "16px",
                fontWeight: "600!important",
                flexDirection: "row",
                alignItems: "center",
                width: "150px!important",
                "& .MuiBottomNavigationAction-label": {
                  marginLeft: "8px",
                },
                "&.Mui-selected": {
                  color: "#F15A29",
                  fontSize: "16px!important",
                  fontWeight: "600!important",
                },
              }}
            />
          ))}
      </Navigation>
      <Grid item className={style.searchAppointmentInputContainer}>
        <input placeholder="Search appointments here" />
        <GetImages name="NavSearchIcon" width="24" height="24" />
      </Grid>
      <Grid item className={style.addPatientDoctorImageContainer}>
        <Grid item className={style.btnContainer}>
          <Button
            btnTrigger={(event) =>
              handleShowHideDropDown(
                IModalShowHide.SHOW,
                DropdownType.ADD_PATIENT,
                event
              )
            }
          >
            Add Patient
          </Button>

          <Menu
            anchorEl={showAddPatient}
            open={isShowAddPatient}
            onClose={() =>
              handleShowHideDropDown(
                IModalShowHide.HIDE,
                DropdownType.ADD_PATIENT
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {addPatientInfo.map((patient, index) => (
              <MenuItem
                onClick={() =>
                  openCloseDoctorProfileDropdown(patient.identifier)
                }
                key={index}
              >
                <ListItemIcon>
                  <GetImages name={patient.iconName} width="20" height="20" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={style.dropdownText} component={"p"}>
                      {patient.text}
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item className={style.doctorImageContainer}>
          <img
            src={NavImage}
            width={48}
            height={48}
            onClick={(event) =>
              handleShowHideDropDown(
                IModalShowHide.SHOW,
                DropdownType.DOCTOR_PROFILE,
                event
              )
            }
          />
          <Menu
            anchorEl={showDoctorProfile}
            open={isShowDoctorProfile}
            onClose={() =>
              handleShowHideDropDown(
                IModalShowHide.HIDE,
                DropdownType.DOCTOR_PROFILE
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {doctorProfileItems.map((doctorProfile, index) => (
              <MenuItem
                onClick={() =>
                  openCloseDoctorProfileDropdown(doctorProfile.identifier)
                }
                key={index}
              >
                <ListItemIcon>
                  <GetImages
                    name={doctorProfile.iconName}
                    width="20"
                    height="20"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={style.dropdownText} component={"p"}>
                      {doctorProfile.text}
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      {doctorProfileDropdown?.isProfileOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isProfileOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(IDoctorProfileDropdownEnums.PROFILE)
          }
        >
          <DoctorProfileDetail />
        </Dialog>
      )}
      {doctorProfileDropdown?.isReviewOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isReviewOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(IDoctorProfileDropdownEnums.REVIEW)
          }
        >
          <DoctorProfile />
        </Dialog>
      )}
      {doctorProfileDropdown.isTemplatesOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isTemplatesOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(
              IDoctorProfileDropdownEnums.TEMPLATES
            )
          }
        >
          <CreateEditTemplate />
        </Dialog>
      )}
      {doctorProfileDropdown.isAvailabilityOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isAvailabilityOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(
              IDoctorProfileDropdownEnums.AVAILABILITY
            )
          }
        >
          <AvailabilitySettingWeb />
        </Dialog>
      )}
    </Grid>
  );
};

export default TopNavigation;
