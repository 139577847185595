import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import TemplateName from "../../../../components/Prescription/TemplateName/TemplateName";
import Title from "../../../../components/Title/Title";
import { InputField } from "../../../../components/InputField/InputFied";
import TitleInputValueList from "../../../../components/Prescription/TitleInputValueList/TitleInputValueList";
import Button from "../../../../components/Button/Button";
import GetImages from "../../../../components/GetImages/GetImages";
import MedicineTestInputSuggestion from "../../../../components/Prescription/MedicineTestInputSuggestion/MedicineTestInputSuggestion";
import Loader from "../../../../components/Loader/Loader";
import SaveCancelTemplate from "../../../../components/Prescription/SaveCancelTemplate/SaveCancelTemplate";
import Confirmation from "../../../../components/Confirmation/Confirmation";

import style from "./CreateEditTemplate.module.css";

import {
  IDoctorProfileDropdownEnums,
  ITemplateCreateEnum,
  ITitleInputValuesListEnums,
} from "../../../../models/doctor";
import { IPrescriptionTemplateList } from "../../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  fetchPrescriptionConfig,
  fetchPrescriptionTemplateList,
  setCreateAndResetPrescriptionTemplateDetail,
  setMakeCopyPrescriptionTemplate,
  setPrescriptionTemplate,
} from "../../../../store/slices/doctor/prescriptionSlice";
import { setDoctorProfileDropdown } from "../../../../store/slices/doctor/topNavigationSlice";

import useDebounce from "../../../../utility/hooks/useDebouncer";
import {
  createPrescriptionTemplateApi,
  deletePrescriptionTemplateApi,
  updatePrescriptionTemplateApi,
} from "../../../../utility/apis/doctor/prescription";
import { INotifyEnum, notify } from "../../../../utility/toaster";
import { doctorProfileDropdownDispatchingEnums } from "../../../../utility";

const CreateEditTemplate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading, template } = useAppSelector((state) => state.prescription);
  const { list, create, isCreateTemplate }: any = template;

  const [templateName, setTemplateName] = useState<string>(create?.name ?? "");
  const [deleteTemplateConfirm, setDeleteTemplateConfirm] =
    useState<boolean>(false);

  const templateNameDebounce = useDebounce(templateName, 800);

  const openClosePrescriptionConfirmPopUp = () =>
    setDeleteTemplateConfirm(!deleteTemplateConfirm);

  const callAfterCreateDeleteEditPrescription = () => {
    dispatch(fetchPrescriptionTemplateList());
    dispatch(setCreateAndResetPrescriptionTemplateDetail());
    setTemplateName("");
  };

  const createPrescriptionTemplateHandler = async () => {
    const { name, diagnosis, dietAndExercise, medicine, test } = create;
    const createPrescriptionTemplateResponse =
      await createPrescriptionTemplateApi({
        name,
        diagnosis,
        dietAndExercise,
        medicine,
        test,
      });
    if ("result" in createPrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      notify(INotifyEnum.SUCCESS, createPrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, createPrescriptionTemplateResponse?.message);
    }
  };

  const deletePrescriptionTemplateHandler = async () => {
    const deletePrescriptionTemplateResponse =
      await deletePrescriptionTemplateApi(create?.id);
    if ("result" in deletePrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      openClosePrescriptionConfirmPopUp();
      notify(INotifyEnum.SUCCESS, deletePrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, deletePrescriptionTemplateResponse?.message);
    }
  };

  const editPrescriptionTemplateHandler = async () => {
    const { name, diagnosis, dietAndExercise, medicine, test } = create;
    const updatePrescriptionTemplateResponse =
      await updatePrescriptionTemplateApi(create?.id, {
        name,
        diagnosis,
        dietAndExercise,
        medicine,
        test,
      });
    if ("result" in updatePrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      notify(INotifyEnum.SUCCESS, updatePrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, updatePrescriptionTemplateResponse?.message);
    }
  };

  useEffect(() => {
    if (templateNameDebounce) {
      dispatch(
        setPrescriptionTemplate({
          identifier: ITemplateCreateEnum.NAME,
          value: templateName,
        })
      );
    }
  }, [templateNameDebounce]);

  useEffect(() => {
    dispatch(fetchPrescriptionTemplateList());
    dispatch(fetchPrescriptionConfig());
  }, []);

  useEffect(() => {
    setTemplateName(create?.name);
  }, [create]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Grid item className={style.createTemplateContainer}>
        {list?.length > 0 && (
          <Grid item className={style.templateListContainer}>
            <Typography component={"p"} className={style.templateCount}>
              {list?.length} Templates
            </Typography>
            <Grid item className={style.templateNameList}>
              <Grid item>
                {list?.map(
                  (detail: IPrescriptionTemplateList, index: number) => (
                    <TemplateName
                      {...detail}
                      key={`${detail?.name}-${index}`}
                    />
                  )
                )}
              </Grid>
              <Grid item className={style.makeNewTemplateBtnContainer}>
                <Button
                  btnTrigger={() =>
                    dispatch(setCreateAndResetPrescriptionTemplateDetail())
                  }
                >
                  Make a new template
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={style.templateCreateFormContainer}>
          <Grid className={style.createTemplateNameActionContainer}>
            <Grid item className={style.createTemplateActions}>
              <Typography component={"h4"}>
                {isCreateTemplate ? "Create New Template" : templateName}
              </Typography>
              {!isCreateTemplate && (
                <Grid item className={style.saveEditMakeCopyActions}>
                  <Button
                    btnTrigger={() =>
                      dispatch(setMakeCopyPrescriptionTemplate())
                    }
                  >
                    Save as a copy
                  </Button>
                  <Button btnTrigger={editPrescriptionTemplateHandler}>
                    Save edits
                  </Button>
                  <Grid
                    item
                    className={style.trashIcon}
                    onClick={openClosePrescriptionConfirmPopUp}
                  >
                    <GetImages name="TrashIcon" width="24" height="24" />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item className={style.templateNameInput}>
              <InputField
                value={templateName || ""}
                placeholder="Template Name"
                label="Template Name"
                onChange={(event) => setTemplateName(event?.target?.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item className={style.diagnosisContainer}>
            <Grid item textAlign={"left"}>
              <Title>Details</Title>
            </Grid>
            <TitleInputValueList
              title="Diagnosis"
              hasActionButton={false}
              buttonLabel={null}
              identifier={ITitleInputValuesListEnums.DIAGNOSIS}
              sliceAttr={"template"}
            />
          </Grid>
          <MedicineTestInputSuggestion />
          <Grid item className={style.dietExerciseContainer}>
            <TitleInputValueList
              title="Diet and Exercise"
              hasActionButton={false}
              buttonLabel={null}
              identifier={ITitleInputValuesListEnums.DIET_AND_EXERCISE}
              sliceAttr={"template"}
            />
          </Grid>
        </Grid>
      </Grid>
      {isCreateTemplate && (
        <SaveCancelTemplate
          isShowIcon
          saveLabel={"Save Template"}
          cancelHandler={() =>
            dispatch(
              setDoctorProfileDropdown(
                doctorProfileDropdownDispatchingEnums[
                  IDoctorProfileDropdownEnums.TEMPLATES
                ]
              )
            )
          }
          saveHandler={createPrescriptionTemplateHandler}
        />
      )}
      {deleteTemplateConfirm && (
        <Confirmation
          isOpen={deleteTemplateConfirm}
          closeHandler={openClosePrescriptionConfirmPopUp}
          title="Delete Prescription template"
          alterMessage="Do you really want to Delete the template"
          takeActionLabel="Yes, Delete"
          dontActionLabel="Don’t Delete"
          actionHandler={deletePrescriptionTemplateHandler}
        />
      )}
    </>
  );
};

export default CreateEditTemplate;
